import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  AreaChart, Area, XAxis, YAxis, ResponsiveContainer, CartesianGrid, Tooltip, Legend, Label,
} from 'recharts'

import { changeMapDay, generateMapData} from '../../../actions/map'
import { toggleLoader, generatingMapData, toggleLeftLoader } from '../../../actions/global'
import { cloneDeep } from 'lodash'
import CustomizedAxisTick from '../CustomizedAxisTick'
import { agriculturalYear, agriculturalYearSubtraction } from '../../../shared/utils/DatesUtils'

class GraphicChart extends Component{
  //Helper methods
  setName(month, day, chartMounting = true){
    let dayString = day < 10 ? '0' + day.toString() : day.toString()

    if(chartMounting){
      month = this.props.visualizationType === 'civil' ? month : agriculturalYear(month)
    }

    return month <= 8
      ? `${dayString}/0${(month + 1).toString()}`
      : `${dayString}/${(month + 1).toString()}`
  }

  fillData(lastDate, targetDate, latestYear, data) {
    for (let i = lastDate; i <= targetDate; i.setDate(i.getDate() + 1)) {
      data.push({
        'name': this.setName(i.getMonth(), i.getDate(), false),
        'year': latestYear +1
      })
    }
  }

  //Render methods
  onClick = (e) => {
    if (!e) {
      return
    }

    const newDay = {}
    newDay.label = e.activeLabel
    newDay.year = e.activePayload[0].payload.year
    newDay.variable = 'excdef'
    const dayAndMonth = newDay.label.split('/')
    const initial_date = newDay.year.toString() + '-' + dayAndMonth[1] + '-' + dayAndMonth[0]
    const final_date = newDay.year.toString() + '-' + dayAndMonth[1] + '-' + dayAndMonth[0]
    const variable = 'excdef'


    //THIS MAY CHANGE LATER IF WE HAVE MORE PERIODS FOR THIS CHART
    const attrs = {
      initial_date,
      final_date,
      type: 'period',
      range: 'daily',
      cad: this.props.lastUsedCad,
      visualization_type: this.props.visualizationType
    }
    
    this.props.setMapDay(newDay)
    this.props.generatingMapData()
    this.props.toggleLeftLoader();
    this.props.generateMapData(variable, attrs)
  }

  render() {
    let dataProps = cloneDeep(this.props.data)
    
    if (dataProps[0]['bhbox'].length > 2) {
      if (this.props.visualizationType === 'civil') {
        dataProps[0]['bhbox'] = dataProps[0]['bhbox']
          .filter(data => data.year === (new Date()).getFullYear())
      }
      else if (agriculturalYearSubtraction(this.props.visualizationType, this.props.data[0])) {
        dataProps[0]['bhbox'] = dataProps[0]['bhbox']
          .filter(data => data.year === (new Date()).getFullYear()-1)
      }
    }

    let data = []
    let arrayField = {}

    dataProps.forEach(row => {
      if (this.props.activeFarm === -1) {
        if (
          (
            row['lat'] === this.props.selectedGridPoint['lat'].toString()
            && row['lng'] === this.props.selectedGridPoint['lng'].toString()
            && this.props.user['type'] === 'bank'
          )
          || this.props.user['type'] !== 'bank'
        ) {
          row['bhbox'].forEach((cell,index) => {
            var myYear = cell['year']
            if (
              this.props.numberOfYears === '1' ? index+1 === row['bhbox'].length-1
              || index+1 === row['bhbox'].length ? true : false : index+1 === row['bhbox'].length ? true : false
            ) {
              cell['hydric_data'].forEach((hydricData, index) => {
                let month = this.props.visualizationType === 'civil'
                  ? index : agriculturalYear(index)
                hydricData.forEach((newData,index) => {
                  let day = index === 0 ? 0 : index === 1 ? 10 : 20
                  newData.forEach((dailyData, index) =>{
                    day += 1;
                    arrayField.name = this.setName(month, day)
                    arrayField.year = myYear
                    if (newData[index] > 0) {
                      arrayField.exc = newData[index]
                    } else if(newData[index] < 0) {
                      arrayField.def = newData[index]
                    } else {
                      arrayField.exc = 0
                      arrayField.def = 0
                    }

                    data.push(arrayField)
                    arrayField = {}
                  })
                })
              })
            }
          })
        }
      } else if(row['farm_id'] === this.props.activeFarm) {
        row['bhbox'].forEach((cell,index) => {
          var myYear = cell['year']
          if(
            this.props.numberOfYears === '1' ? index+1 === row['bhbox'].length-1
            || index+1 === row['bhbox'].length ? true : false : index+1 === row['bhbox'].length ? true : false
          ) {
            cell['hydric_data'].forEach((hydricData, index) => {
              let month = index
              hydricData.forEach((newData,index) => {
                let day = index === 0 ? 0 : index === 1 ? 10 : 20
                newData.forEach((dailyData, index) =>{
                  day += 1
                  arrayField.name = this.setName(month, day)
                  arrayField.year = myYear
                  if(newData[index] > 0){
                    arrayField.exc = newData[index]
                  }else if(newData[index] < 0){
                    arrayField.def = newData[index]
                  }else{
                    arrayField.exc = 0
                    arrayField.def = 0
                  }

                  data.push(arrayField)
                  arrayField = {}
                })
              })
            })
          }
        })
      }
    })

    if (data.length > 0) {
      const latestDate = data[data.length-1].name;
      const latestYear = data[data.length-1].year;
      const latestDayAndMonth = latestDate.split('/');
      const firstDateWithoutData = new Date(
        latestYear, Number(latestDayAndMonth[1]) - 1, Number(latestDayAndMonth[0]) + 1
      );

      if (this.props.visualizationType === 'civil') {
        if (latestDate !== '31/12') {
          const targetDate = new Date(latestYear, 11, 31);
          
          this.fillData(firstDateWithoutData, targetDate, latestYear, data);
        }
      } else {
        if (latestDate !== '30/06') {
          const targetDate = latestDayAndMonth[1] <= 5
            ? new Date(latestYear, 5, 30) : new Date(latestYear + 1, 5, 30);

          this.fillData(firstDateWithoutData, targetDate, latestYear, data);
        }
      }
    }

    let payload = [
      {value: 'Défict Hídrico', type: 'square', id: 'def', color: '#FF0000' },
      {value: 'Excedente Hídrico', type: 'square', id: 'exc', color: '#0000FF'},
    ]

    return(
      <ResponsiveContainer width="100%" height="80%">
        <AreaChart
          onClick={this.onClick}
          data={data}
          margin={{top: 10, right: 30, left: 0, bottom: 0}}
        >
          <XAxis
            dataKey="name"
            tick={<CustomizedAxisTick/>}
            interval={"preserveStartEnd"}
            tickCount={36}
          />
          <YAxis
            type='number'
            yAxisId={1}
            orientation='left'
            tick={{fontSize: '0.95rem'}}
            interval={0}
            tickCount={25}
            domain={[-10, 100]}
          >
            <Label
              angle={-90}
              value={'(mm)'}
              position='insideLeft'
              style={{textAnchor: 'middle'}}
            />
          </YAxis>
          <CartesianGrid strokeDasharray="1 1"/>
          { this.props.showTooltips ? <Tooltip/> : null }
          <Label value='Previsão'/>
          <Area
            yAxisId={1}
            isAnimationActive={false}
            type='linear'
            dataKey='def'
            stroke='#FF0000'
            fillOpacity={1}
            fill='#FF0000'
            unit='mm'
            legendType='square'
          />
          <Area
            yAxisId={1}
            isAnimationActive={false}
            type='linear'
            dataKey='exc'
            stroke='#0000FF'
            fillOpacity={1}
            fill='#0000FF'
            unit='mm'
            legendType='square'
          />
          <Legend
            margin={{top: 20, left: 0, right: 0, bottom: 0}}
            align='center'
            payload={payload}
            wrapperStyle={{bottom: -60}}
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    reportTypes: state.Reports.reportTypes,
    activeDay: state.map.weatherMapDay,
    lastUsedCad: state.Reports.lastUsedCad,
    user: state.auth.user,
    showTooltips: state.global.showTooltips,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setMapDay: (day) => {
      dispatch(changeMapDay(day));
    },
    generateMapData: (reportTypes, attrs) => {
      dispatch(generateMapData(reportTypes, attrs));
    },
    toggleLoader: () => {
      dispatch(toggleLoader());
    },
    toggleLeftLoader: () => {
      dispatch(toggleLeftLoader());
    },
    generatingMapData: () => {
      dispatch(generatingMapData());
    },
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(GraphicChart)
