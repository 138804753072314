import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import SearchInput, {createFilter} from 'react-search-input'
import classnames from 'classnames'
import Checkbox from 'rc-checkbox'
import RadioButtonGroup from '../../common/RadioButtonGroup'
import PerfectScrollbar from 'react-perfect-scrollbar'
import ClickOutside from 'react-click-outside'

class BhboxDropdown extends Component {
  constructor(props){
    super(props)

    this.state ={
      searchTerm: ''
    }
    
    this.radioSelect = this.props.radioSelect
  }

  //Lifecycle methods

  componentDidMount(){
    if(this.props.optionsType === 'years'){
      let attr = {
        type: 'N',
        yearType: this.props.visualizationType,
        yearRange: 'all'
      }
      this.props.getEnos(attr)
    }
  }

  //#region Helper methods

  setCheckStatus(row){
    if(this.props.farms){
      if(row === 'all'){
        let allFarmsFlag = true
        this.props.data.forEach(farm => {
          if(!this.props.farmsCheckedData.includes(farm['id'].toString())){
            allFarmsFlag = false
          }
        })
        return allFarmsFlag
      }else{
        return this.props.farmsCheckedData.indexOf(row['id'].toString()) !== -1
      }
    }else if(row === 'all'){
      let yearsFlag = true
      this.props.years.forEach(year => {
        if(!this.props.checkedData.includes(year.toString())){
          yearsFlag = false
        }
      })
      return yearsFlag
    }else{
      return this.props.checkedData.indexOf(row.toString()) !== -1
    }
  }


  renderOptions(filteredData){
    let radioOptions = []
    let radioOptionsPeriod = []
    let radioOptionsYears = []
    let option = {value: '' , text: ''}
    const hideCurrentYear = this.props.visualizationType === 'agricultural' && new Date().getFullYear() > this.props.lastYearWithData
    const visualizationTypeCivil = this.props.visualizationType === 'civil'

    if(this.props.graphicActive){
      if(this.props.graphicControl){
        const previousYearAgricultural = `${(parseInt(this.props.selectedYear,10)-1).toString().slice(2, 4)}/${(parseInt(this.props.selectedYear,10)).toString().slice(2, 4)}`
        const currentYearAgricultural = `${this.props.selectedYear.toString().slice(2, 4)}/${(parseInt(this.props.selectedYear,10)+1).toString().slice(2, 4)}`

        const dobbleYearAgricultural = `${previousYearAgricultural} e ${currentYearAgricultural}`

        let option1 = this.props.visualizationType === 'civil' ? 
          {value: '0', text: '1 ano - ' + this.props.selectedYear}
          :
          {value: '0', text: '1 ano - ' + currentYearAgricultural}
        radioOptionsPeriod[0] = option1
        if(this.props.firstYear !== this.props.selectedYear){
          let option2 = this.props.visualizationType === 'civil' ?
            {value: '1', text: '2 anos - ' + (parseInt(this.props.selectedYear,10)-1).toString() + ' e ' + this.props.selectedYear}
            :
            {value: '1', text: '2 anos - ' + dobbleYearAgricultural}
            radioOptionsPeriod[1] = option2
        }

        return(
          <RadioButtonGroup name={'options'}
            listOfItems={radioOptionsPeriod}
            selectedItemCallback={this.handleRadio}
            checkedOption={this.radioSelect}
          />
        )
      }else {
        this.props.years.forEach(year => {
          option['value'] = year.toString()
          option['text']  = visualizationTypeCivil ? year.toString() : `${year.toString()}/${Number(year) + 1}`
          radioOptionsYears.push(option)
          option = {value: '' , text: ''}
        })
        if(this.props.visualizationType === 'agricultural' && new Date().getFullYear() > this.props.lastYearWithData){
          radioOptionsYears.pop()
        }

        return(
          <RadioButtonGroup
            name={'options'}
            listOfItems={radioOptionsYears}
            selectedItemCallback={this.handleRadio}
            checkedOption={this.radioSelect}
          />
        )
      }
    }else if(this.props.bhboxType && !this.props.farms){
      filteredData.map((row, index) => {
        option['value'] = row['year'].toString()
        option['text']  = row['year'].toString()
        radioOptions[index] = option
        option = {value: '' , text: ''}
        return radioOptions
      })
      return(
        <RadioButtonGroup
          name={'options'}
          listOfItems={radioOptions}
          selectedItemCallback={this.handleRadio}
          checkedOption={this.radioSelect}
        />
      )
    }else if(this.props.optionsType === 'years'){
      if(this.props.graphicActive){
      }else{
          return(
            <ul>
              <li key={'all'}>
                <label>
                  <Checkbox
                    id={'all'}
                    name={'all'}
                    className='checkbox-item'
                    onChange={this.onChange}
                    checked={this.setCheckStatus('all')}
                  />

                  &nbsp; {'Selecionar todos os anos'}
                </label>
              </li>
              {this.props.years.map(year => {
                if( hideCurrentYear && year === new Date().getFullYear()){
                  return null
                }
                return(
                  <li key={year}>
                    <label>
                      <Checkbox
                        id={year.toString()}
                        name={year.toString()}
                        className='checkbox-item'
                        onChange={this.onChange}
                        checked={this.setCheckStatus(year)}
                      />

                      &nbsp; {year.toString()}
                    </label>
                  </li>
                )
              })
            }
          </ul>
        )
      }
    }else if(this.props.optionsType === 'soil'){
      let soilOptions = [
        {value: 'veryClayey', text: 'Muito Argiloso'},
        {value: 'clayey', text: 'Argiloso'},
        {value: 'mediumTexture', text: 'Textura Média'},
        {value: 'sandyAverageTexture', text: 'Textura Média Arenosa'},
        {value: 'sandy', text: 'Arenosa'}
      ]

      return(
        <RadioButtonGroup
          name={'options'}
          listOfItems={soilOptions}
          selectedItemCallback={this.handleRadioSoil}
          checkedOption={this.props.radioSelect}
        />
      )
    }else{
      return(
        <ul>
          <li>
            <label>
              <Checkbox
                id={'all'}
                name={'all_farms'}
                className='checkbox-item'
                onChange={this.onChange}
                checked={this.setCheckStatus('all')}
              />

              &nbsp; {'Selecionar todas as unidades'}
            </label>
          </li>
          {filteredData.map(row => {
            return(
              <li key={row['id']}>
                <label>
                  <Checkbox
                    id={this.props.farms ? row['id'] : row['year']}
                    name={this.props.farms ? 'points' : row['year']}
                    className='checkbox-item'
                    onChange={this.onChange}
                    checked={this.setCheckStatus(row)}
                  />

                  &nbsp; {this.props.farms ? row['name'].substring(0,20) : row['year']}
                </label>
              </li>
            )
          })}
        </ul>
      )
    }
  }
  //#endregion

  //#region Render methods

  searchUpdated = (term) => {
    this.setState({searchTerm: term})
  }

  handleClick = () => {
    this.props.onClick()
  }

  handleSend = (e) => {
    this.props.onClick()
    this.props.onSendClick(this.radioSelect,e.target.id)
  }

  handleRadio = (e) => {
    this.radioSelect = e
    this.props.onClick()
    this.props.onSendClick(this.radioSelect,'years')
  }

  handleRadioSoil = (e) => {
    this.props.onClick()
    this.props.onSendClick(e,'soil')
  }

  onChange = (newData) => {
    this.props.onChange(newData,newData.target['name'])
  }

  onEnosClick = (event) => {
    let attr = {}
    if(event.target.id === 'all'){
      attr = {
        type: 'N',
        yearType: this.props.visualizationType,
        yearRange: 'all'
      }
    }else{
      attr = {
        type: event.target.id,
        yearType: this.props.visualizationType,
        yearRange: 'yearly'
      }
    }
    this.props.getEnos(attr)

    if (this.props.onEnoEventChange) {
      this.props.onEnoEventChange(event.target.id);
    }
  }

  handleAllChecked = () => { 
    var newArray = []

    if(!this.props.farms){
        if(this.props.checkedData.length >= 0 && this.props.checkedData.length < this.props.data[this.props.reportType].length){
          for(var i=0; i < this.props.data[this.props.reportType].length; i++){
            newArray.push(this.props.data.bhbox[i]['year'].toString())
          }
          this.onChange(newArray)
      }else{
        this.onChange(newArray)
      }
    }
  }

  handleClear = () => {
    if (this.props.clear) {
      this.props.clear();
    }
  }
  //#endregion

  render() {
    //This treatment is here for AgryIndex Planting box
    let label = (this.props.label !== undefined) ? this.props.label : 'Textura Média'
    let filteredData = []
    if(this.props.farms){
      const KEYS_TO_FILTERS = this.props.keysToFilters
      filteredData = this.props.data.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))
    }else if(this.props.agryIndex) {
      filteredData = this.props.data['bhbox']
    }else {
      filteredData = this.props.data[this.props.reportType]
    }

    return(
        <div className={classnames('header-div-item-outline', {'header-div-item-spacing': this.props.rightNeighbor})}>
          <Link to='#' onClick={this.handleClick}>
            <div className="button button--retangle">
              <div className='button--retangle-icon-left'>
                <i className='material-icons'>{this.props.leftIcon}</i>
              </div>
              <div className={classnames('button--retangle-icon-text', {'button--retangle-icon-text-2-letters': label.length === 10}, {'button--retangle-icon-text-3-letters': label.length === 11})}>
                {this.props?.years ? 'Anos' : label}
              </div>
              <div className='button--retangle-icon-right'>
                <i className='material-icons'>arrow_drop_down</i>
              </div>
            </div>
          </Link>
          {this.props.active ?
            <ClickOutside onClickOutside={this.props.onClick}>
              <div className={classnames('dropdown-div',{'period-dropdown': this.props.graphicActive && this.props.graphicControl}, {'soil-dropdown': this.props.optionsType === 'soil'})}>
                <div className={classnames("button button--retangle",{'button--retangle-years': this.props.optionsType === 'years' && this.props.active}, {'button--retangle-active': this.props.active}, {'button--retangle-active-multiple': this.props.optionsType !== 'years'})}>
                  <i className='material-icons button--retangle-icon-left'>{this.props.leftIcon}</i>
                  <div className='button--retangle-icon-text'>
                  {this.props?.years ? 'Anos' : label}
                  </div>
                </div>
                {this.props.optionsType !== 'years' ? null:
                <div className={classnames("button button--retangle", {'button--retangle-active': this.props.active}, {'button--retangle-active-multiple': this.props.optionsType !== 'years'})}>
                  <div className="col s12">
                    <button className="btn-flat btn-drop btn-events" id='EN' onClick={this.onEnosClick}>{'El Niño'}</button>
                    <button className="btn-flat btn-drop btn-events" id='LN' onClick={this.onEnosClick}>{'La Niña'}</button>
                    <button className="btn-flat btn-drop btn-events" id='N' onClick={this.onEnosClick}>{'Neutro'}</button>
                    <button className="btn-flat btn-drop btn-events" id='all' onClick={this.onEnosClick}>{'TODOS'}</button>
                  </div>
                </div>
              }
              <PerfectScrollbar className={classnames(
                {"dropdown-scroll": this.props.optionsType !== 'years'},
                {"dropdown-year-scroll": this.props.optionsType === 'years'},
                {"farms-dropdown-scroll": this.props.farms}
              )}>
                {this.props.farms ? <div><SearchInput className="search-input" placeholder="Buscar Pontos" onChange={this.searchUpdated} /></div> : null}
                <div className={classnames('checkbox-list', {'years-list': !this.props.farms}, {'radio-list': (!this.props.farms && this.props.bhboxType) || this.props.graphicActive})}>
                  {this.renderOptions(filteredData)}
                </div>
              </PerfectScrollbar>
              {this.props.graphicActive || this.props.optionsType === 'soil' || (this.props.bhboxType && this.props.optionsType === 'years') ?
                null:
                <footer className='dropdown-buttons'>
                  {this.props.optionsType === 'years' || this.props.optionsType === 'farms' ? <div onClick={this.handleClear}><img className="img-clear" src="/images/clear-icon.png" alt="LIMPAR"/></div> : null}
                  <div>
                    <button id={this.props.farms ? 'farms' : 'years'} className='btn btn-ok-drop right' onClick={this.handleSend}>OK</button>
                  </div>
                </footer>
              }
            </div>
          </ClickOutside>
          : null}
        </div>
    )
  }

}
export default BhboxDropdown
